
   const setCustomCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    const expiryTime = d.getTime() + exdays * 24 * 60 * 60 * 1000;
    d.setTime(expiryTime);
    // d.setTime(d.getTime() + 60 * 1000); // Test for 60 seconda
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
    return expiryTime;
  };

  const removeCustomCookie = (cname) => {
    // document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    const d = new Date();
  
    // Set it to one month ago
    d.setMonth(d.getMonth() - 1);
  
    // Zero the time component
    d.setHours(0, 0, 0, 0);
  
    setCookieByExpiryDate(cname, '', d);
  };
  
  const getCustomCookie = (cname) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };
